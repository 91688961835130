import React from "react"
import "../assets/styles/page/policy.scss"
import Header from "../components/header"

const Policy = () => {
  return (
    <div>
      <div className="main--page--wrapper">
        <div className="header--container">
          <Header isDarkMode={false} />
        </div>
        <div className="policy--section--container">
          <div className="policies--inner">
            <div className="head--text--wrap">
              <h1>Brooi’s Privacy Policy</h1>
              <p>Last Updated:</p>
            </div>
            <div className="policy--content">
              <h3 className="header--three--wrap">Thank you for using Brooi, your one-stop shop to shortletting!</h3>
              <p className="paragraph--wrapper">
                Welcome to www.brooi.com. We are highly committed to appropriately handling and protecting every
                personal information you give us, or we collect from you. Consequently, we have prepared this Privacy
                Policy to explain to you how we collect, use, disclose, process, store and handle your personal
                information provided to us or acquired in respect of your visit to, use, access of our website, Apps,
                related subdomains, our services, or/and other aspects thereof. Brooi takes your privacy seriously and
                we are committed to ensuring the security, confidentiality, and integrity of every of your personal
                information with us. We ensure that your personal information is managed and processed in accordance
                with the Nigeria Data Protection Regulations 2019 (“NDPR”) and the best international privacy practices
                (particularly the GDPR). While using our website, Apps, related subdomains, our services, or/and other
                aspects thereof, we may provide third party content or links to third-party websites or applications. We
                advise that when you leave our platforms to access or use these third-party websites or applications or
                third-party contents, you ensure you read their privacy policies and terms of service to determine how
                they may handle the personal information they collect from you and process. NB: If you do not agree with
                our privacy practices as stated in our privacy policy, please do not provide us with your personal
                information nor use our website, Apps, and/or Services.
              </p>
              <h3 className="header--three--wrap">Who Are We?</h3>
              <p className="paragraph--wrapper">
                Brooi is your one-stop shop to short-letting. We are a company that provides an online marketplace with
                listings for short-lets, lodgings, primary homestay rentals for vacations and parties. We are your
                trusted bridge to shortlet owners. We connect homeowners with individuals, families and tourists looking
                for accommodations that are more like homes than hotels and apartment for parties. Brooi gets you home
                away from home. Brooi is a product of Alvonant Limited, a company incorporated under the laws of the
                Federal Republic of Nigeria. If you have comments, questions, complaints, and requests concerning this
                Privacy Policy or our Terms of Service, kindly contact us via our Email address, info@brooi.com.
              </p>
              <h3 className="header--three--wrap">What Information Do We Collect?</h3>
              <p className="paragraph--wrapper">
                When you access or use our website, Apps, or Services, we collect your personal information primarily in
                the following ways:
              </p>
              <ul>
                <li>
                  <p className="list--item--paragraph">Information you voluntarily give us</p>
                  <p className="paragraph--wrapper">
                    On creating a Brooi Account or when you communicate with us (via feedbacks, forms, comments,
                    surveys, promotions, complaints, or requests, etc.), you may be required to provide certain
                    information or answer certain questions. This information includes the following, your full name,
                    email address, phone number, username, passwords, address, gender, city, preferred language, bank or
                    payment account details, government ID number, profile picture, credentials for third-party services
                    when you permit us to connect to them on your behalf, and any other required information reasonably
                    necessary for registration or the usage of our Services. Sometimes, we may require further
                    information from you to identify you better while processing your comments, questions, complaints,
                    or requests. Kindly note that all the details that you provide us with shall be appropriately stored
                    encrypted and anonymized from the moment we receive them.
                  </p>
                </li>
                <li>
                  <p className="list--item--paragraph">Information we collect from Third Parties</p>
                  <p className="paragraph--wrapper">
                    To efficiently provide our Services to you, with your consent, for the performance of a contract to
                    which you are subject and where obligated by law, we may collect your personal information from
                    third parties or service providers like landlords, real estate agents, shortlet owners, property
                    owner, management companies and third party Apps through which you connect to the Brooi App.
                  </p>
                </li>
                <li>
                  <p className="list--item--paragraph">Information we collect based on your use of our Services</p>
                  <p className="paragraph--wrapper">
                    Whenever you access or use our services either through our website or Apps, we collect information
                    that is related to your visit, use, or access of our services like your internet protocol (IP)
                    Address, listings, preferences, bookings, download errors, page interaction information, geolocation
                    data, the time and date when you access or use our services, your browser and operating system, the
                    area where you are accessing or using our Services from, etc. This information is collected to
                    enhance your experience and improve our services.
                  </p>
                </li>
                <li>
                  <p className="list--item--paragraph">Information we collect via Cookies</p>
                  <p className="paragraph--wrapper">
                    We may obtain your personal information via our cookies whenever you access or use our Services. We
                    employ Session Cookies and Analytics Cookies to enable important functionality of our website, Apps,
                    and Services especially in respect of security, network management, and accessibility, web
                    navigation, your preferences, analyzing trends, statistics, web usage, and activities, etc. For more
                    information on the kinds of Cookies we use and how we use them, read the Cookies section under this
                    Privacy Policy.
                  </p>
                </li>
              </ul>
              <p className="paragraph--wrapper">
                Kindly note that all the details that you provide us with or the ones that we acquire under this Privacy
                Policy shall be appropriately stored encrypted and anonymized from the moment we receive them
              </p>
              <h3 className="header--three--wrap">How Do We Use Your Information?</h3>
              <p className="paragraph--wrapper">
                We may use the personal information obtained from you whenever you access or use our services either
                through our website or Apps in the following ways:
              </p>
              <ul>
                <li>
                  <p className="paragraph--wrapper">
                    To ensure the smooth operation of our website, Apps, Services, and features.
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    To process financial account details and ensure safer transactions amongst parties using our
                    website, Apps, and Services.
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    For data analysis and statistical reports to maintain, sustain, provide support, protect, and
                    enhance our Services.
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">To comply with regulatory and legal requirements</p>
                </li>
                <li>
                  <p className="paragraph--wrapper">To prevent and detect fraud and other illegal activities.</p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    To effectively manage your Brooi Account and your relationship with us (customer support).
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    To generate anonymized data and share anonymized data with our affiliates, subsidiaries, agents,
                    and third parties permitted by you.
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">To verify your identity and financial information.</p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    To provide you with information about our products, promotions, services, or changes to our
                    services. NB: You may opt-out of this service at any time by contacting us through the means
                    provided in this Privacy Policy.
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">For other reasons authorized or mandated by law</p>
                </li>
              </ul>
              <h3 className="header--three--wrap">Do We Disclose or Share Your Information?</h3>
              <p className="paragraph--wrapper">
                Except as provided by law or in line with the other lawful bases stipulated by the NDPR, we shall not
                disclose, sell, lease, rent, license, or share your personal information in our custody with anyone or a
                third party without your consent. However, for effective provision of our Services, analytical purposes,
                and purposes mentioned above, we may share your personal information with Brooi affiliates, agent,
                employees, subsidiaries, third-party service providers necessary for your access or use of our services.
                We may also transfer your personal information to other jurisdictions or countries while adhering
                strictly to the applicable data protection laws (NDPR and GDPR), or they may be transferred during the
                sale of the Company to an assign or successor in title
              </p>
              <h3 className="header--three--wrap">How Long Will We Store Your Information?</h3>
              <p className="paragraph--wrapper">
                In accordance with the applicable data protection laws, we will not store your personal information
                longer than it is reasonably necessary for the purposes it is collected and processed under this Privacy
                Policy. Nonetheless, we will store your data with us for a minimum of 1year and a maximum of 5 years or
                the number of years prescribed at law.
              </p>
              <p className="paragraph--wrapper">
                We may delete or erase your personal information from our custody where:
              </p>
              <ul className="disc">
                <li>
                  <p className="paragraph--wrapper">
                    You exercise your right to erasure, or you withdraw your consent and where there is no legitimate
                    reason for us to keep your personal information.
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    Where you delete or deactivate your Brooi Account on our website or Apps.
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    Where we delete your account or refuse you access or use of our services due to your breach of our
                    Terms of Service.
                  </p>
                </li>
              </ul>
              <h3 className="header--three--wrap">Legal Bases for Processing Your Information</h3>
              <p className="paragraph--wrapper">
                Under the NDPR, apart from processing your personal information according to your consent, we will
                obtain and process your personal information on the following legal bases:
              </p>
              <ul>
                <li>
                  <p className="paragraph--wrapper">
                    Where the processing is necessary for the performance of a contract to which you are a party.
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    Where the processing is necessary for us to comply with a legal obligation or the performance of a
                    task carried out in the public interest.
                  </p>
                </li>
              </ul>
              <h3 className="header--three--wrap">What Are Your Rights?</h3>
              <p className="paragraph--wrapper">In accordance with the NDPR, you have the following rights:</p>
              <ul className="disclosure">
                <li>
                  <p className="paragraph--wrapper">
                    <span className="list--ittem--option">The right to be informed </span>: you have the right to
                    receive fair processing information about any or all personal information processed by us, including
                    the recipients or categories of recipients to whom your personal information has been or will be
                    disclosed, transfers to recipients in third countries or international organizations, and the
                    appropriate safeguards relating to such transfers.
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    <span className="list--ittem--option">The right of access </span>: you possess the right to request
                    and be given access to your personal information
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    <span className="list--ittem--option">The right to restrict processing </span>: you have the right
                    to restrict the processing of your personal information as prescribed by law.
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    <span className="list--ittem--option">The right to data portability </span>: you may request to
                    receive, free of charge, a copy of your personal information in our custody in a structured,
                    commonly used, and machine-readable format or have us transmit the information directly to another
                    controller if this is technically feasible
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    <span className="list--ittem--option"> The right to object </span>: You possess the right to object
                    to (i) processing based on legitimate interests or the performance of a task in the public
                    interest/exercise of official authority (including profiling); (ii) direct marketing (including
                    profiling); and (iii) processing for purposes of scientific/historical research and statistics. We
                    do not process your personal information for direct marketing or for purposes of
                    scientific/historical research and statistics.
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    <span className="list--ittem--option">
                      The right to lodge a complaint with a Supervisory Authority
                    </span>
                    : you can lodge a complaint about our privacy practices, or the exercise of any of your rights
                    concerning your personal information as stated in this Privacy Noice, with your local Supervisory
                    Authority.
                  </p>
                </li>
                <li>
                  <p className="paragraph--wrapper">
                    <span className="list--ittem--option">The right to withdraw consent </span>: Where consent is the
                    legal basis for processing, you can withdraw your consent to our processing of your data at any time
                    by contacting us via the channel outlined in this Privacy Policy.
                  </p>
                </li>
              </ul>
              <h3 className="header--three--wrap">Is Your Information Safe And Confidential With Us?</h3>
              <p className="paragraph--wrapper">
                Yes! We implement appropriate technical and organizational measures to protect your personal information
                against accidental or unlawful destruction, loss, change, or damage. All personal information we collect
                will be stored on secure servers and anonymized once received. All electronic transactions entered via
                our website will be protected by Secure Sockets Layer (SSL) encryption technology. Brooi shall put
                security measures in place to identify and mitigate security risks to technology assets that process or
                store your data on our platforms.
              </p>
              <p className="paragraph--wrapper">
                Note that, where you register for an account on our website or Apps, you are solely responsible for
                maintaining your account’s security as more specifically explained in our Terms of Service.
              </p>
              <h3 className="header--three--wrap">Will There be Third-Party Ads?</h3>
              <p className="paragraph--wrapper">
                Yes! Our services may include the presence of Third-Party Ads or links to third-party operated websites.
                The inclusion of such links does not imply an association, support, endorsement, consent, examination,
                or authentication by Brooi of such third party or their services (including but not limited to any
                content made available within such third-party services). Access to any Third-Party Services is at the
                User's risk, and User must bear in mind that Third-Party Services are governed by their own terms of
                service and privacy policys which may be different from those of Brooi'.
              </p>
              <h3 className="header--three--wrap">What Happens If a Personal Data Breach Occurs?</h3>
              <p className="paragraph--wrapper">
                The likelihood of this is at the barest minimum but peradventure a personal data breach occurs, we will
                promptly notify the appropriate data protection authority after we become aware of such breach. In
                circumstances where such breach largely affects the rights and freedoms of the Users of our services, we
                will also promptly notify the affected Users of such breach.
              </p>
              <h3 className="header--three--wrap">Subsequent Privacy Policy Updates</h3>
              <p className="paragraph--wrapper">
                We may change, modify, amend, or update this Privacy Policy occasionally. Whenever we effect these
                changes, we will notify you by posting a policy at the top of our Privacy Policy page on our website.
                You are advised to periodically visit our Privacy Policy to ensure that you are aware of any subsequent
                changes.
              </p>
              <h3 className="header--three--wrap">Contact Us</h3>
              <p className="paragraph--wrapper">
                Brooi is the property of Brooi Fintech Inc. which is resident in Toronto, Ontario, Canada. If you have
                any questions, comments, or feedback concerning this Privacy Policy, kindly contact us at info@brooi.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Policy
